/* =WordPress Core
-------------------------------------------------------------- */

.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  height: auto;
}

.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: auto;
}

.alignleft,
.alignright {
  height: auto;
}

@media #{$breakpoint-md-up} {
  .alignleft { float: left; }
  .alignright { float: right; }
}

.wp-caption {
  max-width: 100%;
  line-height: 1;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  @media #{$breakpoint-md-up} {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
}

.wp-caption-text {
  margin-top: 1rem;
  @media #{$breakpoint-md-up} {
    margin-top: 1.5rem;
  }
  @include font-size($fs-xs);
  color: $text-inactive;
  display: block;
  line-height: 1.6;
}

img[class*='wp-image-'] {
  vertical-align: bottom;
}
