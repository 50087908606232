
//
// Panel
// --------------------------------------------------

%panel-base {
  margin-bottom: 2rem;
  @media #{$breakpoint-sm-up} {
    margin-bottom: 3rem;
  }
}

%poster-base {
  background-position: 50%;
  background-repeat: none;
  background-size: cover;
}

.panel-container {
  @media #{$breakpoint-sm-up} {
    display: flex;
    flex-wrap: wrap;
    //justify-content: center;
    margin-left: -1rem;
    margin-right: -1rem;
    overflow: hidden;
    .ias-trigger, .ias-spinner, .ias-noneleft {
      width: 100%;
    }
  }
  @media #{$breakpoint-md-up} {
    margin-left: -2rem;
    margin-right: -2rem;
  }
}

.panel {
  @extend %panel-base;
  @media #{$breakpoint-sm-up} {
    margin-left: 1rem;
    margin-right: 1rem;
    width: calc(50% - 2rem);
  }
  @media #{$breakpoint-md-up} {
    margin-left: 2rem;
    margin-right: 2rem;
    width: calc(50% - 4rem);
  }
  &-link {
    display: block;
    &:hover {
      .panel-poster-inner {
        transform: scale(1.01);
        opacity: .8;
      }
    }
  }
  &-poster {
    background: #000;
    overflow: hidden;
    @include aspect-ratio(16, 9);
    &-inner {
      transition: all .1s linear;
      @extend %poster-base;
    }
  }
  &-summary {
    &-title {
      @include font-size($fs-base);
      margin: 1em 0 0;
      font-weight: normal;
      > i { vertical-align: middle; }
    }
  }
}
