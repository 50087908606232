
//
// Publications
// --------------------------------------------------

.publication-item {
  border-top: 1px solid $border-color;
  padding: 1em 0;
  > p {
    line-height: 1.4;
    margin: .5em 0;
    > a {
      @extend %link-bordered;
    }
  }
}
