
//
// Front Page
// --------------------------------------------------

/* Keyframes */
@keyframes bg-diagonal {
  from { background-position: 0 640px; }
  to { background-position: -640px 0; }
}

body > section {
  position: relative;
  z-index: 2;
  background-color: #fff;
}

/* Cover */
.cover {
  position: relative;
  z-index: 10;
  height: 100vh;
  background-color: #f2f2f2;
  background-image: url(#{$images}bg.svg);
  animation: bg-diagonal 30s linear infinite;
  .brand {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
  }
  .button-oval {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2rem;
    @media #{$breakpoint-sm-up} {
      bottom: 4rem;
    }
  }
}

.lead {
  font-weight: $headings-font-weight;
}

/* Intro */
.intro-primary {
  position: relative;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: bottom left, top right;
  background-size: contain;
  background-image: url(#{$images}poster_intro_sp.jpg);
  background-size: cover;
  @include aspect-ratio(1, 1);
  &:before {
    // Avoid to prevent click by overlay
    position: relative;
    z-index: -1;
  }
  @media #{$breakpoint-md-up} {
    background-size: contain;
    background-color: #f2f2f2;
    background-image: url(#{$images}poster_intro_pc_01.png), url(#{$images}poster_intro_pc_02.png);
    background-attachment: fixed;
    height: 100vh;
  }
  > .intro-container {
    > .intro-content {
      position: fixed;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      > p {
        text-align: center;
        @media #{$breakpoint-sm-up} {
          @include font-size($fs-base);
        }
        @media #{$breakpoint-md-up} {
          @include font-size($fs-lg);
          line-height: 2;
        }
      }
    }
  }
}

.intro-secondary {
  background-color: #f2f2f2;
  > .intro-container {
    display: flex;
    align-items: center;
    justify-content: center;
    > .intro-content {
      padding: 4rem;
      @media #{$breakpoint-lg-up} {
        padding: 0 8rem;
      }
      > p {
        text-align: center;
      }
    }
  }
}

.section-header {
  h1, .h1, h2, .h2, h3, .h3,
  h4, .h4, h5, .h5, h6, .h6 {
    margin-top: 0;
    @include font-size($fs-huge);
    //white-space: nowrap;
    > small {
      @include font-size($fs-lg);
      letter-spacing: 0;
      display: block;
      font-family: 'Roboto Condensed', $font-family-base;
      margin-bottom: .5em;
    }
  }
}

.section-content {
  p {
    @include font-size($fs-sm);
  }
}

.split {
  text-align: center;
  @media #{$breakpoint-sm-up} {
    text-align: left;
    display: flex;
    &-primary, &-secondary {
      width: 50%;
    }
  }
  &-primary {
    @include aspect-ratio(1, 1);
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    background-image: url(#{$images}poster_philosophy.svg);
  }
  &-secondary {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-content {
    max-width: 64rem;
    padding: 4rem 4rem;
    @media #{$breakpoint-lg-up} {
      padding: 0 4rem;
    }
  }
  .section-content > p {
    @media #{$breakpoint-sm-up} {
      html[lang='ja'] & {
        text-align: justify;
      }
    }
  }
}

.vision {
  text-align: center;
  &-primary {
  }
  &-secondary {
    padding: 0 2rem 4rem;
    @media #{$breakpoint-lg-up} {
      padding: 0 4rem 4rem;
    }
  }
}

.platform {
  background: #eee;
  text-align: center;
  &-primary {
    padding: 4rem 2rem 0;
    @media #{$breakpoint-lg-up} {
      padding: 4rem 4rem 0;
    }
  }
  &-secondary {
    @media #{$breakpoint-xs-only} {
      [class*='container'] {
        [class*='col'] {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
    margin-top: 4rem;
    padding: 0 2rem;
    @media #{$breakpoint-lg-up} {
      padding: 0 4rem;
    }
  }
  &-tertiary {
    padding: 0 2rem 4rem;
    @media #{$breakpoint-lg-up} {
      padding: 0 4rem 4rem;
    }
  }
  &-item {
    display: block;
    > * {
      transition: opacity .1s linear;
    }
    &:hover {
      > * {
        opacity: .8;
      }
    }
    &-poster {
      @include aspect-ratio(16, 9);
      background-size: cover;
      background-position: 50%;
      background-repeat: no-repeat;
    }
    &-caption {
      display: block;
      margin: 1em 0 2rem;
      @include font-size($fs-xs);
    }
  }
}

.member {
  &-primary {
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    background-image: url(#{$images}poster_member.jpg);
    @include aspect-ratio(4, 3);
    @media #{$breakpoint-lg-up} {
      background-attachment: fixed;
      @include aspect-ratio(21, 9);
    }
  }
  &-secondary {
    text-align: center;
    padding: 4rem 2rem;
    @media #{$breakpoint-lg-up} {
      padding: 4rem;
    }
  }
  &-item {
    margin-top: 4rem;
    dt {
      margin: 0 auto 1em;
      max-width: 18rem;
      position: relative;
      small {
        display: block;
        border-radius: 50%;
        width: 72px;
        height: 72px;
        position: absolute;
        top: 0;
        left: -36px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include font-size($fs-tiny);
        background: #000;
        color: #fff;
        &.alpha { background: $brand-alpha; }
        &.beta  { background: $brand-beta; }
        &.gamma { background: $brand-gamma; }
        &.delta { background: $brand-delta; }
        span {
          line-height: 1.4;
          margin-top: .2rem;
        }
      }
      i {
        display: block;
        width: 18rem;
        height: 18rem;
        background-size: cover;
        background-position: 50%;
        background-repeat: no-repeat;
        border-radius: 50%;
      }
    }
    dd {
      margin: 0 0 1em;
      line-height: 1.5;
      @include font-size($fs-xs);
      strong {
        @include font-size($fs-lg);
        display: block;
      }
      small {
        @include font-size($fs-tiny);
        color: lighten($text-color, 20%);
      }
    }
  }
}

@keyframes show{
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.ex-member {
  display: none;
  &.is-visible {
    display: flex;
    animation: show .1s linear 0s;
  }
}

.contact {
  text-align: center;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  background-color: #f2f2f2;
  background-image: url(#{$images}bg_contact_sp.svg);
  padding: 4rem 2rem;
  @media #{$breakpoint-lg-up} {
    padding: 4rem;
    background-image: url(#{$images}bg_contact_pc.svg);
  }
  .button-container {
    margin-top: 4rem;
    overflow: visible;
  }
  .copyright {
    margin-top: 4rem;
    @include font-size($fs-tiny);
    p, ul {
      margin: 2rem 0 0;
    }
    small {
      display: block;
      margin-top: 1em;
    }
  }
}

.news {
  padding: 4rem 2rem;
  @media #{$breakpoint-lg-up} {
    padding: 4rem;
  }
  text-align: center;
  .section-footer {
    margin-top: 4rem;
  }
}
