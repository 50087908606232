//
// Layout
// --------------------------------------------------

.main {
  @extend .container-sm;
  .post-type-archive-projects & {
    @extend .container-lg;
  }
  padding: 10rem 2rem 5rem;
  @media #{$breakpoint-md-up} {
    padding: 12rem 4rem 6rem;
  }
}
