
//
// Navigation
// --------------------------------------------------

.pagetop {
  opacity: 0;
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  z-index: 5;
  transition: all .1s linear;
  &.show {
    opacity: 1;
    bottom: 2rem;
  }
  &.hide {
    opacity: 0;
    bottom: 1.5rem;
  }
  @media #{$breakpoint-sm-up} {
    right: 4rem;
    bottom: 4rem;
    &.show {
      bottom: 4rem;
    }
    &.hide {
      bottom: 3rem;
    }
  }
}

header[rel='banner'] {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 7;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 6rem;
  background: #fff;
  transition: height .1s linear;
  box-shadow: 0 0 10px 0 rgba(0,0,0,.3);
  @media #{$breakpoint-md-up} {
    display: block;
  }
  .brand {
    position: fixed;
    top: 1.8rem;
    left: 50%;
    margin-left: -12px;
    @media #{$breakpoint-md-up} {
      margin-left: 0;
      left: 4rem;
    }
  }
  .is-open & {
    z-index: 11;
    height: 100%;
    .nav-primary {
      display: block;
    }
  }
}

.nav-primary {
  display: none;
  @media #{$breakpoint-md-up} {
    display: block;
  }
}

.nav-menu {
	position: absolute;
  top: 2.2rem;
  right: 2rem;
  width: $bar-width;
	height: $bar-height + $bar-spacing * 2;
	cursor: pointer;
  @media #{$breakpoint-md-up} {
    display: none;
  }
}

.bar,
.bar:after,
.bar:before {
  width: $bar-width;
	height: $bar-height;
}

.bar {
	position: relative;
	transform: translateY($bar-spacing);
	background: rgba(0,0,0, 1);
	transition: all 0s .3s;
  &.animate {
    background: rgba(0,0,0, 0);
    &:before {
    	bottom: 0;
    	transform: rotate(-45deg);
    	transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);;
    }
    &:after {
    	top: 0;
    	transform: rotate(45deg);
    	transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);;
    }
  }
  &:before {
  	content: "";
  	position: absolute;
  	left: 0;
  	bottom: $bar-spacing;
  	background: rgba(0,0,0, 1);
  	transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  &:after {
  	content: "";
  	position: absolute;
  	left: 0;
  	top: $bar-spacing;
  	background: rgba(0,0,0, 1);
  	transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }
}

.list-nav {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
  @media #{$breakpoint-md-up} {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  > li {
    > a {
      font-family: 'Roboto Condensed', $font-family-base;
      letter-spacing: 0;
      display: block;
      padding: .5em;
      @include font-size($fs-xl);
      @media #{$breakpoint-md-up} {
        display: inline-block;
        padding: 0 1.5em;
        height: 6rem;
        line-height: 6rem;
        @include font-size($fs-base);
      }
    }
  }
}

.nav-secondary {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  z-index: 1;
  transform: translateX(-50%);
  display: none;
  .is-open & {
    display: block;
  }
}

.nav-tertiary {
  position: fixed;
  top: 0;
  right: 2.5rem;
  z-index: 99;
  display: none;
  @media #{$breakpoint-md-up} {
    display: block;
  }
}

#scroll-indicator-bullets {
  @media #{$breakpoint-xs-only} {
    display: none;
  }
  position: fixed;
  right: 4rem;
  top: 50%;
  z-index: 9;
  transform: translateY(-50%);
  @include font-size($fs-sm);
  > ul {
    position: relative;
    list-style: none;
    text-align: right;
    margin-top: -1rem;
    > li {
      margin-top: 1rem;
      font-family: 'Roboto Condensed', $font-family-base;
      > a.bullet-item-link {
        position: relative;
        color: #000;
        > span.bullet-nav-title {
          letter-spacing: 0;
          transition: right .1s linear, opacity .1s linear;
          position: relative;
          right: 0;
          opacity: 0;
          display: inline-block;
          background: #fff;
          padding: 0 1em;
          border-radius: ($font-size-sm * $line-height) / 2;
        }
        > i.circle {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 0;
          height: 1rem;
          width: 1rem;
          border-radius: 50%;
          background: #fff;
          border: 1px solid #999;
        }
        &.active {
          > span.bullet-nav-title {
            right: 2rem;
            opacity: 1;
          }
          > i.circle {
            background: #000;
          }
        }
      }
      &.show-title {
        > a.bullet-item-link {
          > span.bullet-nav-title {
            right: 2rem;
            opacity: 1;
          }
          > i.circle {
            background: #000;
          }
        }
      }
    }
  }
}
