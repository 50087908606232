//
// Entry
// --------------------------------------------------

.entry-header {
  text-align: center;
  margin: 0 0 3rem;
  @media #{$breakpoint-md-up} {
    margin: 0 0 4rem;
  }
  &-label {
    font-weight: bold;
    @include font-size($fs-lg);
  }
  &-title {
    text-align: left;
    @include font-size($fs-huge);
    margin: 1rem 0;
    @media #{$breakpoint-lg-up} {
      margin: 2rem 0;
    }
  }
  &-poster {
    margin: 2rem 0;
    @media #{$breakpoint-lg-up} {
      margin: 3rem 0;
    }
  }
  &-meta {
    text-align: left;
    color: $text-inactive;
  }
}

.entry-content {
  a {
    @extend %link-bordered;
  }
  > p > img {
    margin-top: 2rem;
    margin-bottom: 2rem;
    @media #{$breakpoint-md-up} {
      margin-top: 3rem;
      margin-bottom: 3rem;
    }
  }
  &-credit {
    @include font-size($fs-sm);
    color: $text-inactive;
  }
  &-gallery {
    margin-top: 3rem;
    @media #{$breakpoint-sm-up} {
      display: flex;
      flex-wrap: wrap;
      margin-left: -1rem;
      margin-right: -1rem;
      overflow: hidden;
    }
    &-image {
      margin: 0 0 2rem;
      @media #{$breakpoint-sm-up} {
        margin: 0 1rem 3rem;
        width: calc(50% - 2rem);
      }
      background: #000;
      overflow: hidden;
      @include aspect-ratio(16, 9);
      @extend %poster-base;
    }
  }
}

.entry-footer {
  margin: 6rem 0 0;
}

.entry-content-asset {
  margin-top: 2rem;
  margin-bottom: 2rem;
  @media #{$breakpoint-md-up} {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  text-align: center;
  > * {
    max-width: 100%;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  @media #{$breakpoint-xs-only} {
    > .fb_iframe_widget_fluid {
      > span {
        width: 100% !important;
        iframe { width: 100% !important; }
      }
    }
  }
  &-video {
    @include fluid-video(16, 9);
  }
}

.entry-projects {
  .entry-header {
    &-title {
      text-align: center;
      font-weight: normal;
    }
    &-poster {
      background: #000;
      overflow: hidden;
      @include aspect-ratio(16, 9);
      &-inner {
        @extend %poster-base;
      }
    }
  }
}
