
//
// List
// --------------------------------------------------

.list-lang {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  > li {
    font-family: 'Roboto Condensed', $font-family-base;
    @include font-size($fs-lg);
    @media #{$breakpoint-md-up} {
      @include font-size($fs-sm);
    }
    letter-spacing: 0;
    height: 6rem;
    line-height: 6rem;
    > a {
      color: $text-inactive;
      border-right: 1px solid $border-color;
      padding: 0 1.5rem;
      &:hover {
        color: #000;
      }
    }
    &:last-child {
      > a {
        border-right: none;
      }
    }
    > .current_language {
      color: #000;
      font-weight: bold;
    }
  }
}

.list-vertical {
  list-style: none;
  text-align: right;
  margin-top: -.5rem;
  > li {
    font-family: 'Roboto Condensed', $font-family-base;
    position: relative;
    .active, &:hover {
      > a {
        opacity: 1;
        right: 2rem;
      }
    }
    > a {
      transition: right .1s linear, opacity .1s linear;
      opacity: 0;
      position: relative;
      right: 0;
    }
    > span {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      height: 1rem;
      width: 1rem;
      border-radius: 50%;
      background: #aaa;
    }
  }
}

.list-horizontal {
  margin: 0;
  text-align: left;
  @media #{$breakpoint-sm-up} {
    display: flex;
    flex-wrap: wrap;
  }
  dt, dd {
    @media #{$breakpoint-sm-up} {
      padding: 1em 0;
    }
  }
  dt {
    @media #{$breakpoint-xs-only} {
      font-size: $font-size-xs;
    }
    @media #{$breakpoint-sm-up} {
      border-top: 1px solid $border-color;
      width: 20%;
      padding-right: 2%;
    }
  }
  dd {
    @media #{$breakpoint-xs-only} {
      border-bottom: 1px solid $border-color;
      margin: 0 0 .7em;
      padding: 0 0 .7em;
      > a {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    @media #{$breakpoint-sm-up} {
      border-top: 1px solid $border-color;
      margin: 0;
      width: 78%;
      > a {
        position: relative;
        display: block;
        padding-left: 1.5em;
        > i {
          position: absolute;
          top: .4em;
          left: 0;
        }
      }
    }
  }
}

.list-archive {
  text-align: center;
  @extend %list-unstyled;
  @extend %list-inline;
  margin: 0 0 2rem;
  @media #{$breakpoint-lg-up} {
    margin: 0 0 4rem;
  }
  > li {
    > a {
      @extend %button-base;
      @extend %button-ghost;
      @extend %button-xs;
    }
  }
}

.list-sns-link {
  @extend %list-unstyled;
  @extend %list-inline;
  @include font-size($fs-xl);
  a {
    display: inline-block;
    i {
      vertical-align: middle;
    }
  }
}

.list-sns-share {
  @extend %list-unstyled;
  @extend %list-inline;
}

.fb_iframe_widget > span {
	vertical-align: baseline !important;
}

.list-pagination {
  display: flex;
  justify-content: center;
  margin: 0;
  @extend %list-unstyled;
  @extend %list-inline;
  > li {
    width: 30%;
    margin: 0;
    text-align: center;
    padding: 0 1.5%;
    &:first-child { text-align: right; }
    &:last-child { text-align: left; }
    > a {
      @extend %dont-break-out;
      > i {
        vertical-align: middle;
      }
    }
  }
}
