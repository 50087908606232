//
// Fonts
// --------------------------------------------------

@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?qvj750');
  src:  url('../fonts/icomoon.eot?qvj750#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?qvj750') format('truetype'),
    url('../fonts/icomoon.woff?qvj750') format('woff'),
    url('../fonts/icomoon.svg?qvj750#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1.2;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-chevron-down:before {
  content: "\e908";
}
.icon-chevron-left:before {
  content: "\e909";
}
.icon-chevron-right:before {
  content: "\e90a";
}
.icon-chevron-small-down:before {
  content: "\e900";
}
.icon-chevron-small-left:before {
  content: "\e901";
}
.icon-chevron-small-right:before {
  content: "\e902";
}
.icon-chevron-small-up:before {
  content: "\e903";
}
.icon-chevron-thin-down:before {
  content: "\e904";
}
.icon-chevron-thin-left:before {
  content: "\e905";
}
.icon-chevron-thin-right:before {
  content: "\e906";
}
.icon-chevron-thin-up:before {
  content: "\e907";
}
.icon-chevron-up:before {
  content: "\e90b";
}
.icon-facebook:before {
  content: "\ea90";
}
.icon-twitter:before {
  content: "\ea96";
}
.icon-youtube:before {
  content: "\ea9d";
}
