
//
// Headings
// --------------------------------------------------

.page-header {
  margin: 0 0 2rem;
  @media #{$breakpoint-lg-up} {
    margin: 0 0 4rem;
  }
  text-align: center;
  > h1 {
    margin: 0;
  }
}
