//
// Infinite Ajax Sroll
// --------------------------------------------------

.posts-navigation {
  display: none;
}

@keyframes spin {
  from { transform:rotate(0deg); }
  to { transform:rotate(360deg); }
}

.ias-trigger, .ias-spinner, .ias-noneleft {
  margin-top: 4rem;
}

.ias-noneleft {
  color: $text-inactive;
}

.ias-trigger {
  > a {
    @extend %button-base;
  }
}

.ias-spinner {
  height: 45px;
  position: relative;
  @media #{$breakpoint-lg-up} {
    height: 50px;
  }
  > img {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -15px 0 0 -15px;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
  }
}
