//
// Button
// --------------------------------------------------

[class^='button-'] {
  i {
    vertical-align: middle;
  }
}

%button-base {
  display: inline-block;
  transition: all .1s linear;
  padding: .7em 2em;
  box-shadow: 0 1px 2px rgba(0,0,0,0.2);
  border-radius: 1.9em;
  background: #f2f2f2;
  &:visited, &:active, &:focus {
    background: #f2f2f2;
    text-decoration: none;
  }
  &:hover {
    background: #f5f5f5;
    text-decoration: none;
  }
  i, img {
    vertical-align: middle;
  }
}

%button-lg {
  @include font-size($fs-lg);
}

%button-sm {
  @include font-size($fs-sm);
}

%button-xs {
  @include font-size($fs-xs);
  padding: .5em 1.5em;
}

%button-oval {
  display: block;
  background: #fff;
  border-radius: 50%;
  text-align: center;
  width: 48px;
  height: 48px;
  line-height: 48px;
  transition: all .1s linear;
  box-shadow: 0 1px 2px rgba(0,0,0,0.2);
  i, img {
    vertical-align: middle;
    @include font-size($fs-xl);
  }
  @media #{$breakpoint-sm-up} {
    width: 72px;
    height: 72px;
    line-height: 72px;
  }
  &:visited, &:active, &:focus {
    background: #fff;
    text-decoration: none;
  }
  &:hover {
    background: #f2f2f2;
    text-decoration: none;
  }
}

%button-primary {
  background: #000;
  color: #fff;
  &:visited, &:active, &:focus {
    background: #000;
    color: #fff;
  }
  &:hover {
    color: #fff;
    background: #333;
  }
}

%button-inverted {
  background: #fff;
  &:visited, &:active, &:focus {
    background: #fff;
  }
  &:hover {
    background: #f5f5f5;
  }
}

%button-ghost {
  background: transparent;
  border: 1px solid #000;
  box-shadow: none;
  line-height: 1.2;
  &:visited, &:active, &:focus {
    background: transparent;
  }
  &:hover {
    background: transparent;
  }
}

%button-link {
  white-space: nowrap;
  display: inline-block;
  transition: all .1s linear;
  color: $text-color;
  border-bottom: 1px solid $text-color;
  &:hover {
    text-decoration: none;
    color: inherit;
    border-bottom-color: transparent;
  }
}

.button {
  &-default {
    @extend %button-base;
  }
  &-primary {
    @extend %button-base;
    @extend %button-primary;
  }
  &-inverted {
    @extend %button-base;
    @extend %button-inverted;
  }
  &-ghost {
    @extend %button-base;
    @extend %button-ghost;
  }
  &-xs {
    @extend %button-xs;
  }
  &-sm {
    @extend %button-sm;
  }
  &-lg {
    @extend %button-lg;
  }
  &-oval {
    @extend %button-oval;
  }
  &-link {
    @extend %button-link;
  }
}
